@tailwind base;
@tailwind components;
@tailwind utilities;

/* ================ FONTS ================ */

div,
a,
p,
span,
input,
::placeholder,
textarea,
label,
nav,
button {
  font-family: "graphie", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "graphie", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bold {
  font-weight: 500 !important;
}

/* ================ COLORS ================ */

.color-main {
  color: #ea5b0c;
}

.bg-main {
  background-color: #ea5b0c;
}

.color-add {
  color: #444444;
}

.bg-add {
  background-color: #444444;
}

/* ================ BUTTONS ================ */

.button-main {
  background-color: #ea5b0c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-main:hover {
  background-color: #ffffff;
  color: #ea5b0c;
  transition: all 0.3s;
}

.button-main svg path {
  transition: 0.3s all;
}

.button-main:hover svg path {
  fill: #ea5b0c;
  transition: all 0.3s;
}

.button-white {
  background-color: #ffffff;
  color: #ea5b0c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-white:hover {
  background-color: #ea5b0c;
  color: #ffffff;
  transition: all 0.3s;
}

.button-white svg path {
  transition: 0.3s all;
}

.button-white:hover svg path {
  fill: #ea5b0c;
  transition: all 0.3s;
}

.button-add {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  transition: all 0.3s;
  cursor: pointer;
  padding: 10px 20px;
}

.button-add:hover {
  background-color: #ffffff;
  color: #ea5b0c;
  transition: all 0.3s;
}

.button-main-outlined {
  background-color: transparent;
  color: #ea5b0c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #ea5b0c;
}

.button-main-outlined:hover {
  background-color: #ea5b0c;
  color: #ffffff;
  transition: all 0.3s;
}

.button-main-outlined svg path {
  transition: 0.3s all;
  stroke: #ea5b0c;
}

.button-main-outlined:hover svg path {
  stroke: #ffffff;
  transition: all 0.3s;
}

.button-disabled {
  background-color: #e2966d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: not-allowed;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* ================ SVG ================ */

svg .svg-elem-1 {
  stroke-dashoffset: 71.09577178955078px;
  stroke-dasharray: 71.09577178955078px;
  -webkit-transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

/* ================ LINKS ================ */

.header-link {
  color: #444444;
  text-decoration: none;
  transition: all 0.3s;
}

.header-link:hover {
  color: #ea5b0c;
  transition: all 0.3s;
}

.underline-white {
  color: #ffffff;
  text-decoration: none;
  background-image: linear-gradient(#ffffff, #ffffff);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.underline-white:hover,
.underline-white:focus,
.underline-white:active {
  background-size: 100% 0.1em;
  background-position-x: 0%;
}

.underline-main {
  color: #3a3a3a;
  text-decoration: none;
  background-image: linear-gradient(#ea5b0c, #ea5b0c);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.underline-main:hover,
.underline-main:focus,
.underline-main:active {
  background-size: 100% 0.1em;
  background-position-x: 0%;
  color: #ea5b0c;
}

/* ================ SVG ================ */

svg .svg-elem-1 {
  stroke-dashoffset: 2039.718017578125px;
  stroke-dasharray: 2039.718017578125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-2 {
  stroke-dashoffset: 4668.74951171875px;
  stroke-dasharray: 4668.74951171875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-3 {
  stroke-dashoffset: 2792.897216796875px;
  stroke-dasharray: 2792.897216796875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-4 {
  stroke-dashoffset: 906.5836791992188px;
  stroke-dasharray: 906.5836791992188px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-5 {
  stroke-dashoffset: 381.3301696777344px;
  stroke-dasharray: 381.3301696777344px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg-elem-5 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-6 {
  stroke-dashoffset: 294.5204162597656px;
  stroke-dasharray: 294.5204162597656px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg-elem-6 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-7 {
  stroke-dashoffset: 294.4873046875px;
  stroke-dasharray: 294.4873046875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg-elem-7 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-8 {
  stroke-dashoffset: 294.5177307128906px;
  stroke-dasharray: 294.5177307128906px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg-elem-8 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-9 {
  stroke-dashoffset: 294.4888000488281px;
  stroke-dasharray: 294.4888000488281px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg-elem-9 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-10 {
  stroke-dashoffset: 718.752685546875px;
  stroke-dasharray: 718.752685546875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg-elem-10 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-11 {
  stroke-dashoffset: 326.6186218261719px;
  stroke-dasharray: 326.6186218261719px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg-elem-11 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-12 {
  stroke-dashoffset: 371.8837890625px;
  stroke-dasharray: 371.8837890625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg-elem-12 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-13 {
  stroke-dashoffset: 327.3576965332031px;
  stroke-dasharray: 327.3576965332031px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-13 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-14 {
  stroke-dashoffset: 102.64893341064453px;
  stroke-dasharray: 102.64893341064453px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}

svg.active .svg-elem-14 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-15 {
  stroke-dashoffset: 102.50869750976562px;
  stroke-dasharray: 102.50869750976562px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}

svg.active .svg-elem-15 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-demo {
  stroke-dashoffset: 77.16838836669922px;
  stroke-dasharray: 77.16838836669922px;
  -webkit-transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

svg.active .svg-elem-demo {
  stroke-dashoffset: 0;
}

svg .svg-elem-demo-2 {
  stroke-dashoffset: 77.16838836669922px;
  stroke-dasharray: 77.16838836669922px;
  -webkit-transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
  transition: stroke-dashoffset 2s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-demo-2 {
  stroke-dashoffset: 0;
}

/* ================ SHADOWS ================ */

.shadow-main {
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.08);
}

/* ================ CASE STUDY ================ */

.text-box-casestudy {
  text-align: justify;
}

.text-box-casestudy h2 {
  font-size: 1.8rem;
  color: #ea5b0c;
}

.text-box-casestudy h3 {
  font-size: 1.6rem;
  color: #ea5b0c;
}

.text-box-casestudy h4 {
  font-size: 1.4rem;
  color: #ea5b0c;
}

.text-box-casestudy h5 {
  font-size: 1.2rem;
  color: #ea5b0c;
}

.text-box-casestudy h6 {
  font-size: 1rem;
  color: #ea5b0c;
}

.text-box-casestudy p {
  font-weight: 400;
  color: #444444;
  margin-bottom: 20px;
}

.text-box-casestudy a {
  font-weight: 400;
  color: #ea5b0c;
}

.text-box-casestudy a:hover {
  color: #444444;
}

/* ================ DOCUMENTS ================ */

article {
  display: flex;
  flex-direction: column;
}

article p, article a, article span, article ul, article ol, article li {
  font-size: 1.2rem;
  color: #444444;
  text-align: justify;
}

article h1 {
  font-size: 2.6rem;
  color: #ea5b0c;
  margin-bottom: 20px;
}

article h2 {
  font-size: 2.4rem;
  color: #ea5b0c;
  margin: 25px 0px;
}

article h3 {
  font-size: 2rem;
  color: #ea5b0c;
}

article h4 {
  font-size: 1.8rem;
  color: #ea5b0c;
}

article h5 {
  font-size: 1.6rem;
  color: #ea5b0c;
}

article h6 {
  font-size: 1.4rem;
  color: #ea5b0c;
}

article ul li {
  list-style-type: decimal;
  margin-left: 20px;
}

article ol li {
  list-style-type: decimal;
  margin-left: 20px;
}